// @ts-check
import { storeToRefs } from 'pinia';
import { io } from 'socket.io-client';
import { watch } from 'vue';
import { useFeatureAccessStore } from '../store/pinia/feature-access';
import { useSessionStore } from '../store/pinia/session';
import { getGuiSession } from '../utils/session';
import axios from 'axios';

export const useFeatureAccessEvent = () => {
  const { token, id: userId, role: roleId } = getGuiSession() ?? {};

  /**
   * @type { 'development' | 'production' }
   */
  // @ts-ignore
  const appEnv = process.env.VUE_APP_ENV;

  if (token === undefined) {
    return;
  }

  const restrictionStore = useFeatureAccessStore();
  const {
    isUserRestrictedFeatureUpdated,
    isRoleRestrictedFeatureUpdated,
    userIdToBeRestricted,
    roleIdToBeRestricted,
    restrictedFeatures,
    isDoneFetching,
    features
  } = storeToRefs(restrictionStore);

  // @ts-ignore
  const socket = io(process.env.VUE_APP_WS_URL, { auth: { token }, transports: ['websocket'], reconnectionAttempts: 3, });

  socket.on('connect', () => console.log('WebSocket Status: Connected'));
  socket.on('disconnect', () => console.log('WebSocket Status: Disconnected'));

  const gui = useSessionStore().currentGUI;

  const getClassnames = async () => {
    const endpoint = `${process.env.VUE_APP_URL}/api/user-restricted-features?filter[user_id]=${userId}&include=feature`;
    const headers = { headers: { Authorization: `Bearer ${token}`, gui }};

    const { status, data } = await axios.get(endpoint, headers);
    if (status !== 200) {
      return false;
    }

    /**
     * @type { Array<{ feature: { class_name: string } }> }
     */
    const userRestrictedFeatures = data.data;
    const classnames = userRestrictedFeatures.filter(userRestrictedFeature => userRestrictedFeature.feature !== null)
      .map(userRestrictedFeature => userRestrictedFeature.feature.class_name);

    return classnames;
  }

  const getFeatures = async () => {
    const headers = { headers : { Authorization: `Bearer ${token}`, gui } };
    const { status, data } = await axios.get(`${process.env.VUE_APP_URL}/api/features`, headers);

    if (status !== 200) {
      return false;
    }

    /** @type { Feature[] } */
    const __features__ = data.data;

    /**
     * Purposely added value to features from pinia store here.
     */
    features.value = __features__;

    return features;
  };

  socket.emit('userRestrictedFeatures:send', { userId, gui, appEnv });

  socket.on('userRestrictedFeatures:listen', async (message) => {
    if (message.appEnv !== appEnv) {
      return;
    }

    if (message.userId !== userId) {
      return;
    }

    // No need to await for this function because features is already assigned with value inside it.
    getFeatures();

    const classnames = await getClassnames();

    if (!classnames) {
      console.error('Failed to get classnames on userRestrictedFeatures:listen');
      return;
    }

    restrictedFeatures.value = classnames;
    isDoneFetching.value = true;
  });

  watch(isUserRestrictedFeatureUpdated, (value) => {
    if (!value) {
      return;
    }

    socket.emit('userRestrictedFeatures:send', { userId: userIdToBeRestricted.value, appEnv });
    isUserRestrictedFeatureUpdated.value = false;
  });

  watch(isRoleRestrictedFeatureUpdated, (value) => {
    if (!value) {
      return;
    }

    socket.emit('roleRestrictedFeatures:send', { roleId: roleIdToBeRestricted.value, appEnv });
    isRoleRestrictedFeatureUpdated.value = false;
  });

  socket.on('roleRestrictedFeatures:listen', async (message) => {
    if (message.appEnv !== appEnv) {
      return;
    }

    if (message.roleId !== roleId) {
      return;
    }

    // No need to await for this function because features is already assigned with value inside it.
    getFeatures();

    const classnames = await getClassnames();

    if (!classnames) {
      console.error('Failed to get classnames on roleRestrictedFeatures:listen');
      return;
    }

    restrictedFeatures.value = classnames;
  });
};

/**
 * @typedef { object } Feature
 * @property { number } id
 * @property { number } [parent_id]
 * @property { 'verification' | 'case management' | 'admin' } gui
 * @property { string } page_name
 * @property { string } name
 * @property { string } type
 * @property { string } class_name
 * @property { string } description
 * @property { 0 | 1 } is_required
 * @property { 0 | 1 } is_active
 */
