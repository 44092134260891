import Vue from 'vue'
import App from './App.vue'
import store from './store/index'
import routes from './routes'
import VueRouter from 'vue-router'
import VueSession from 'vue-session'
import HTTP from "./AxiosIndex"
import layouts from './layouts'
import Toast from 'vue-toastification'
import "vue-toastification/dist/index.css";
import VueTooltip from 'v-tooltip';
import VuePortal from '@linusborg/vue-simple-portal'
import VueLazyload from 'vue-lazyload'
import showFeatureDirective from './custom-directives/show-feature'
import { isFeatureRestricted } from './utils/common'
import { useRestrictFeature } from './directives/restrict-feature';
import { usePinia } from './plugins/pinia';
import VueI18n from 'vue-i18n'
import { useFeatureAccessStore } from './store/pinia/feature-access'
import axios from 'axios'
import { featureAccessPrototype } from './prototypes/feature-access'

if (process.env.VUE_APP_ENV !== 'production') {
    Vue.config.devtools = true;
}

const pinia = usePinia();
useRestrictFeature();

Vue.config.productionTip = false

/*Ignoring push to same route-->*/
const originalPush       = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(VueTooltip)
VueTooltip.options.defaultTemplate = '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow"></div><div class="tooltip-vue-inner"></div></div>';
VueTooltip.options.defaultArrowSelector = '.tooltip-vue-arrow, .tooltip-vue__arrow';
VueTooltip.options.defaultInnerSelector = '.tooltip-vue-inner, .tooltip-vue__inner';
VueTooltip.options.popover.defaultArrowClass = 'tooltip-vue-arrow popover-arrow';
VueTooltip.options.popover.defaultInnerClass = 'tooltip-vue-inner popover-inner';
VueTooltip.options.popover.defaultBaseClass = 'tooltip-vue popover';

Vue.use(VueRouter)
Vue.use(VueSession,{persist:true})
Vue.use(Toast, {
    transition: "Vue-Toastification__fade",
    maxToasts: 1,
    newestOnTop: true
})
Vue.use(VuePortal, {
    name: 'portal'
})

const errorImage = require('./assets/IMG_BROKEN.svg')
Vue.use(VueLazyload)
Vue.prototype.$layouts = layouts

export const router = new VueRouter({
    routes,
    mode:'history',
    //Redirect to div when inputting id on url
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
          return savedPosition;
      }
      if (to.hash) {
        return { selector: to.hash };
      }

      return { x: 0, y: 0 };
  }
});

const checkMaintenance = async (url) => {
    return HTTP.get(url)
    .then(data => {
        data = data.data
        if(typeof data.data[0] !== 'undefined'){
            if(data.data[0].status == 1){
                return true
            }
        }
        return false
      
    })
    .catch((error) => {
        console.error({error});
        return false
    });
};

// router guard for additional security --> session trapping, campaign access, case tab access
router.beforeEach(async (to, from, next) => {
    let will_check_maintenance = false
    let app_url = process.env.VUE_APP_URL
    let gui_path = to.path?.includes('/verification') ? 'ver' 
                    : to.path?.includes('/lawfirm') ? 'cm' 
                    : to.path?.includes('/admin') ? 'admin' 
                    : 'notfound'

    let env_path = app_url?.includes('stage') ? 'stage' 
                    : app_url?.includes('beta') ? 'beta' 
                    : 'live'

    const notIncludes = gui => !to.path.split('/').filter(Boolean).includes(gui)
    //Redirect to Verification if to path includes 'verifi'
    if(to.path.includes('verifi') && notIncludes('verification')) {
        await next()
        next('/verification')
    }
    //Redirect to Admin if to path includes 'admi'
    if(to.path.includes('admi') && notIncludes('admin')) {
        await next()
        next('/admin')
    }
    //Redirect to Lawfirm if to path includes 'lawfi'
    if(to.path.includes('lawfi') && notIncludes('lawfirm')) {
        await next()
        next('/lawfirm')
    }

    if(!to.path.includes('verifi') && !to.path.includes('admi') && !to.path.includes('lawfi')) {
        await next()
        next('/lawfirm/404')
    }

    if(process.env.NODE_ENV == 'development')
        env_path = 'stage'

    if(will_check_maintenance) {
        if(await checkMaintenance(`maintenance_page/${env_path}/${gui_path}`)) {
            if(to.path != '/maintenance'){
                next('/maintenance')
            }
            else {
                next()
            }
        }
    } else {
        // Check if the destination is login pages and continue if it is
        if (['admin', 'verification', 'lawfirm'].includes(to.name)) {
            const isLoggedIn = (toName, item) => to.name === toName && localStorage.getItem(item) !== null

            //Do not show login pages when already logged in
            if(isLoggedIn('lawfirm', 'lawfirm')) {
                const isClient = () => JSON.parse(localStorage.getItem('lawfirm'))?.access_level == 6;

                /**
                 * Uncomment the '/lawfirm/listings/review'
                 * and comment the other for development
                 * 
                 * Vice versa for hiding the Dashboard
                 * 
                 * Uncomment below for Hiding CM Dashboard for Client
                 */
                const to = isClient() ? '/lawfirm/listings/review' /*'/lawfirm/dashboard'*/ : '/lawfirm/dashboard';
                next(to);
                // next('/lawfirm/dashboard')
            }
            else if(isLoggedIn('verification', 'ver')) {
                const isQA = () => JSON.parse(localStorage.getItem('ver'))?.access_level == 2
                // next('/verification/my/tasks')
                const to = isQA() ? '/verification/qa/start-shift' : '/verification/my/tasks'
                next(to);
            }
            else if(isLoggedIn('admin', 'admin')) {
                next('/admin/dashboard')
            }

            //Redirect to login
            next()
        } else {
            if (location.pathname.includes("/verification") && localStorage.getItem("ver") == null){ // check if GUI is "verification" and if token is null
                //Proceed first to next route to be recorded in route history
                await next()
                //Get the value of previous route
                sessionStorage.setItem('prev_path', to.fullPath)
                //Redirect to login
                next('/verification')
                /*setTimeout(() => {
                    sessionStorage.setItem('prev_path', to.fullPath)
                    next('/verification')//Redirect to login
                });*/
            }
            else if (location.pathname.includes("/lawfirm") && localStorage.getItem("lawfirm") == null){ // check if GUI is "case management" and if token is null
                //Proceed first to next route to be recorded in route history
                await next()

                // check if "to" link has not "token" query parameters
                if (to.query.token) {
                    console.log('token found')
                    // proceed to link
                    next(to)
                } else {
                    //Get the value of previous route
                    sessionStorage.setItem('prev_path', to.fullPath)
                    //Redirect to login
                    next('/lawfirm')
                }
                /*setTimeout(() => {
                    sessionStorage.setItem('prev_path', to.fullPath)
                    next('/lawfirm')//Redirect to login
                });*/
            }
            else if (location.pathname.includes("/admin") && localStorage.getItem('admin') == null) { // check if GUI is "admin" and if token is null
                //Proceed first to next route to be recorded in route history
                await next()
                //Get the value of previous route
                sessionStorage.setItem('prev_path', to.fullPath)
                //Redirect to login
                next('/admin')
                /*setTimeout(() => {
                    sessionStorage.setItem('prev_path', to.fullPath)
                    next('/admin')//Redirect to login
                });*/
            }
            else {
                let path = to.path
                let gui = path.includes('/verification')
                    ? 'ver' : path.includes('/lawfirm')
                        ? 'lawfirm' : 'admin'

                let is_lawfirm_logged_in = gui === 'lawfirm' && localStorage.getItem("lawfirm") != null
                let is_ver_logged_in = gui === 'ver' && localStorage.getItem("ver") != null
                let is_admin_logged_in = gui === 'admin' && localStorage.getItem("admin") != null

                const hasCaseStatusAccess = () => {
                    let access = ((JSON.parse(localStorage.getItem("lawfirm")) || {}).case_status_filter || 0)
                    if (!is_lawfirm_logged_in)
                        return false

                    return ([7, 6, 3].includes(JSON.parse(localStorage.getItem("lawfirm"))?.access_level)) || access != 0
                }

                const hasCampaignAccess = () => {
                    let canAccess = true

                    if (is_lawfirm_logged_in || is_ver_logged_in) {
                        if (["export", "lawfirmexport"].includes(to.name))
                            return true;

                        let user = JSON.parse(localStorage.getItem(gui))

                        if (user.cxids !== undefined && user.cxids != null) {
                            if (!user.cxids.some(item => item.id == to.params.campaign_id))
                                canAccess = false
                        }
                    }

                    return canAccess
                }

                const hasAdminAccountingAccess = () => {
                    if (!is_admin_logged_in)
                        return false

                    return (JSON.parse(localStorage.getItem('admin'))?.case_status_filter || [0]).includes(5)
                }

                const isSuperAdmin = () => {
                    return JSON.parse(localStorage.getItem("lawfirm"))?.access_level == 7
                }

                const isClient = () => {
                    return JSON.parse(localStorage.getItem('lawfirm'))?.access_level == 6
                }

                const isQA = () => {
                    return JSON.parse(localStorage.getItem('ver')).access_level
                }
                if((JSON.parse(localStorage.getItem('ver'))?.access_level == 2) && (to.path.includes('my/task') || to.path.includes('my/campaigns') || to.path.includes('my/listings'))) {
                    next({name: gui + "notauthorized"})
                    return
                }

                if((JSON.parse(localStorage.getItem('ver'))?.access_level != 2) && (to.path.includes('qa/start-shift') || (to.path.includes('qa/listings')))) {
                    next({name: gui + "notauthorized"})
                    return
                }
                // Replaced with feature access restriction
                /* if (!hasAdminAccountingAccess() && gui == 'admin' && ["admincampaignaccounting", "adminaccountingreports"].includes(to.name)) { // check if user has accounting page access and if gui is admin
                    next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
                    return
                } */

                // check if user can access CM Submissions Pack Monitoring, (Update) Check also if user can access CM counterfeit checker - Marvin 03/30/2022
                /* if (!isSuperAdmin() && gui === 'lawfirm' && ["lawfirmsubmissionspackmonitoring"].includes(to.name)){
                    next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
                    return
                } */

                /**
                 * Uncomment below for Hiding CM Dashboard for Client
                 */
                if (isClient() && gui === 'lawfirm' && ["lawfirmdashboard"].includes(to.name)){
                    next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
                    return
                }

                // check if user can access CM Submissions Pack Monitoring, (Update) Check also if user can access CM counterfeit checker - Marvin 03/30/2022
                // if ((!isSuperAdmin() && !isClient()) && gui === 'lawfirm' && ["lawfirmcounterfeitchecker"].includes(to.name)){
                //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
                //     return
                // }

                // check if campaign_id parameter is not undefined and if gui is not admin
                if (typeof to.params.campaign_id != 'undefined' && gui !== 'admin') {
                    // if (!hasCaseStatusAccess() && to.params.status == 'Cases') { // check if user has no filter 'Cases' status access and if the filter status selected is 'Cases'
                    //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
                    //     return
                    // }

                    // if (!isSuperAdmin() && gui === 'lawfirm' && ["lawfirmnewcase", "lawfirmeditcaselisting"].includes(to.name)){
                    //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
                    //     return
                    // }

                    if (!hasCampaignAccess()) { // check if user has no access to current campaign
                        next({name: gui + "notauthorized"}) // if true, redirect to 403 page
                        return
                    }

                    // Restrict Client from accessing the "Has Potential" status (HARD CODED) - Marvin 07/26/2024
                    // Changed from isClient to isClient() - Earl 7/29/2024
                    if (isClient() && to.params?.status?.toLowerCase() == "not covered") {
                        next({name: gui + "notauthorized"}) // if true, redirect to 403 page
                        return
                    }

                    // (Added) Check also if user can access CM counterfeit checker - Marvin 03/30/2022
                    // if ((!isSuperAdmin() && !isClient()) && gui === 'lawfirm' && ["lawfirmcampaigncounterfeitchecker"].includes(to.name)){
                    //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
                    //     return
                    // }
                }

                if(sessionStorage.getItem('prev_path') != null){ //Check if there is failed route (Redirected to login because no token provided)
                    next(sessionStorage.getItem('prev_path'))//Proceed to failed route
                    sessionStorage.removeItem('prev_path')
                } else // if all passed then redirect to destination
                    next()
            }
        }
    }
    // if(await checkMaintenance(`maintenance_page/${env_path}/${gui_path}`)) {
    //     if(to.path != '/maintenance'){
    //         next('/maintenance')
    //     }
    //     else {
    //         next()
    //     }
    // } else {
    //     // Check if the destination is login pages and continue if it is
    //     if (['admin', 'verification', 'lawfirm'].includes(to.name)) {
    //         const isLoggedIn = (toName, item) => to.name === toName && localStorage.getItem(item) !== null

    //         //Do not show login pages when already logged in
    //         if(isLoggedIn('lawfirm', 'lawfirm')) {
    //             const isClient = () => JSON.parse(localStorage.getItem('lawfirm'))?.access_level == 6;

    //             /**
    //              * Uncomment the '/lawfirm/listings/review'
    //              * and comment the other for development
    //              * 
    //              * Vice versa for hiding the Dashboard
    //              * 
    //              * Uncomment below for Hiding CM Dashboard for Client
    //              */
    //             const to = isClient() ? '/lawfirm/listings/review' /*'/lawfirm/dashboard'*/ : '/lawfirm/dashboard';
    //             next(to);
    //             // next('/lawfirm/dashboard')
    //         }
    //         else if(isLoggedIn('verification', 'ver')) {
    //             const isQA = () => JSON.parse(localStorage.getItem('ver'))?.access_level == 2
    //             // next('/verification/my/tasks')
    //             const to = isQA() ? '/verification/qa/start-shift' : '/verification/my/tasks'
    //             next(to);
    //         }
    //         else if(isLoggedIn('admin', 'admin')) {
    //             next('/admin/dashboard')
    //         }

    //         //Redirect to login
    //         next()
    //     } else {
    //         if (location.pathname.includes("/verification") && localStorage.getItem("ver") == null){ // check if GUI is "verification" and if token is null
    //             //Proceed first to next route to be recorded in route history
    //             await next()
    //             //Get the value of previous route
    //             sessionStorage.setItem('prev_path', to.fullPath)
    //             //Redirect to login
    //             next('/verification')
    //             /*setTimeout(() => {
    //                 sessionStorage.setItem('prev_path', to.fullPath)
    //                 next('/verification')//Redirect to login
    //             });*/
    //         }
    //         else if (location.pathname.includes("/lawfirm") && localStorage.getItem("lawfirm") == null){ // check if GUI is "case management" and if token is null
    //             //Proceed first to next route to be recorded in route history
    //             await next()

    //             // check if "to" link has not "token" query parameters
    //             if (to.query.token) {
    //                 console.log('token found')
    //                 // proceed to link
    //                 next(to)
    //             } else {
    //                 //Get the value of previous route
    //                 sessionStorage.setItem('prev_path', to.fullPath)
    //                 //Redirect to login
    //                 next('/lawfirm')
    //             }
    //             /*setTimeout(() => {
    //                 sessionStorage.setItem('prev_path', to.fullPath)
    //                 next('/lawfirm')//Redirect to login
    //             });*/
    //         }
    //         else if (location.pathname.includes("/admin") && localStorage.getItem('admin') == null) { // check if GUI is "admin" and if token is null
    //             //Proceed first to next route to be recorded in route history
    //             await next()
    //             //Get the value of previous route
    //             sessionStorage.setItem('prev_path', to.fullPath)
    //             //Redirect to login
    //             next('/admin')
    //             /*setTimeout(() => {
    //                 sessionStorage.setItem('prev_path', to.fullPath)
    //                 next('/admin')//Redirect to login
    //             });*/
    //         }
    //         else {
    //             let path = to.path
    //             let gui = path.includes('/verification')
    //                 ? 'ver' : path.includes('/lawfirm')
    //                     ? 'lawfirm' : 'admin'

    //             let is_lawfirm_logged_in = gui === 'lawfirm' && localStorage.getItem("lawfirm") != null
    //             let is_ver_logged_in = gui === 'ver' && localStorage.getItem("ver") != null
    //             let is_admin_logged_in = gui === 'admin' && localStorage.getItem("admin") != null

    //             const hasCaseStatusAccess = () => {
    //                 let access = ((JSON.parse(localStorage.getItem("lawfirm")) || {}).case_status_filter || 0)
    //                 if (!is_lawfirm_logged_in)
    //                     return false

    //                 return ([7, 6, 3].includes(JSON.parse(localStorage.getItem("lawfirm"))?.access_level)) || access != 0
    //             }

    //             const hasCampaignAccess = () => {
    //                 let canAccess = true

    //                 if (is_lawfirm_logged_in || is_ver_logged_in) {
    //                     if (["export", "lawfirmexport"].includes(to.name))
    //                         return true;

    //                     let user = JSON.parse(localStorage.getItem(gui))

    //                     if (user.cxids !== undefined && user.cxids != null) {
    //                         if (!user.cxids.some(item => item.id == to.params.campaign_id))
    //                             canAccess = false
    //                     }
    //                 }

    //                 return canAccess
    //             }

    //             const hasAdminAccountingAccess = () => {
    //                 if (!is_admin_logged_in)
    //                     return false

    //                 return (JSON.parse(localStorage.getItem('admin'))?.case_status_filter || [0]).includes(5)
    //             }

    //             const isSuperAdmin = () => {
    //                 return JSON.parse(localStorage.getItem("lawfirm"))?.access_level == 7
    //             }

    //             const isClient = () => {
    //                 return JSON.parse(localStorage.getItem('lawfirm'))?.access_level == 6
    //             }

    //             const isQA = () => {
    //                 return JSON.parse(localStorage.getItem('ver')).access_level
    //             }
    //             if((JSON.parse(localStorage.getItem('ver'))?.access_level == 2) && (to.path.includes('my/task') || to.path.includes('my/campaigns') || to.path.includes('my/listings'))) {
    //                 next({name: gui + "notauthorized"})
    //                 return
    //             }

    //             if((JSON.parse(localStorage.getItem('ver'))?.access_level != 2) && (to.path.includes('qa/start-shift') || (to.path.includes('qa/listings')))) {
    //                 next({name: gui + "notauthorized"})
    //                 return
    //             }
    //             // Replaced with feature access restriction
    //             /* if (!hasAdminAccountingAccess() && gui == 'admin' && ["admincampaignaccounting", "adminaccountingreports"].includes(to.name)) { // check if user has accounting page access and if gui is admin
    //                 next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
    //                 return
    //             } */

    //             // check if user can access CM Submissions Pack Monitoring, (Update) Check also if user can access CM counterfeit checker - Marvin 03/30/2022
    //             /* if (!isSuperAdmin() && gui === 'lawfirm' && ["lawfirmsubmissionspackmonitoring"].includes(to.name)){
    //                 next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
    //                 return
    //             } */

    //             /**
    //              * Uncomment below for Hiding CM Dashboard for Client
    //              */
    //             if (isClient() && gui === 'lawfirm' && ["lawfirmdashboard"].includes(to.name)){
    //                 next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
    //                 return
    //             }

    //             // check if user can access CM Submissions Pack Monitoring, (Update) Check also if user can access CM counterfeit checker - Marvin 03/30/2022
    //             // if ((!isSuperAdmin() && !isClient()) && gui === 'lawfirm' && ["lawfirmcounterfeitchecker"].includes(to.name)){
    //             //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
    //             //     return
    //             // }

    //             // check if campaign_id parameter is not undefined and if gui is not admin
    //             if (typeof to.params.campaign_id != 'undefined' && gui !== 'admin') {
    //                 // if (!hasCaseStatusAccess() && to.params.status == 'Cases') { // check if user has no filter 'Cases' status access and if the filter status selected is 'Cases'
    //                 //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
    //                 //     return
    //                 // }

    //                 // if (!isSuperAdmin() && gui === 'lawfirm' && ["lawfirmnewcase", "lawfirmeditcaselisting"].includes(to.name)){
    //                 //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
    //                 //     return
    //                 // }

    //                 if (!hasCampaignAccess()) { // check if user has no access to current campaign
    //                     next({name: gui + "notauthorized"}) // if true, redirect to 403 page
    //                     return
    //                 }

    //                 // Restrict Client from accessing the "Has Potential" status (HARD CODED) - Marvin 07/26/2024
    //                 // Changed from isClient to isClient() - Earl 7/29/2024
    //                 if (isClient() && to.params?.status?.toLowerCase() == "not covered") {
    //                     next({name: gui + "notauthorized"}) // if true, redirect to 403 page
    //                     return
    //                 }

    //                 // (Added) Check also if user can access CM counterfeit checker - Marvin 03/30/2022
    //                 // if ((!isSuperAdmin() && !isClient()) && gui === 'lawfirm' && ["lawfirmcampaigncounterfeitchecker"].includes(to.name)){
    //                 //     next({name: gui + 'pagenotfound'}) // if true, redirect to 404 page
    //                 //     return
    //                 // }
    //             }

    //             if(sessionStorage.getItem('prev_path') != null){ //Check if there is failed route (Redirected to login because no token provided)
    //                 next(sessionStorage.getItem('prev_path'))//Proceed to failed route
    //                 sessionStorage.removeItem('prev_path')
    //             } else // if all passed then redirect to destination
    //                 next()
    //         }
    //     }
    // }
    // }

    
    

    // if(to.path != '/maintenance'){
    //     HTTP.get(`${app_url}/maintenance_page/${env_path}/${gui_path}`)
    //     .then(data => {
    //         data = data.data
    //         if(typeof data.data[0] !== 'undefined'){
    //             if(data.data[0].status == 1){
    //                 is_maintenance = true
    //                 next('/maintenance')
    //             }
    //         }
    //         to_do()
    //     })
    //     .catch((error) => {
    //         console.error({error});
    //     });
    // }
});

window.sleep = ms => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

//Custom Directives
showFeatureDirective;


// #region : Algorithms that checking user authentication and redirect unauthorize requests 

const responseInterceptChecking = (error) => {
    const response = error.response;
    if (response && response.status === 401) {
        if (location.pathname.includes("verification") && localStorage.getItem('ver') != null) {
            localStorage.removeItem('ver')
            vm.$router.replace({name:"vernosession"})
        } else if (location.pathname.includes("admin") && localStorage.getItem('admin') != null) {
            localStorage.removeItem('admin')
            vm.$session.remove("admin")
            vm.$session.remove("admin_headers")
            vm.$router.replace({name:"adminnosession"})
        } else if (location.pathname.includes("lawfirm") && localStorage.getItem('lawfirm') != null) {
            localStorage.removeItem("token")
            localStorage.removeItem("headers")
            localStorage.removeItem("lawfirm")
            vm.$router.replace({name:"lawfirmnosession"})
        }
    }
}

HTTP.interceptors.response.use(
    response => response, 
    error => {
        responseInterceptChecking(error)
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => response, 
    error => {
        responseInterceptChecking(error)
        return Promise.reject(error);
    }
);
//#endregion

// #region : Algorithm that intercepts requests for the extend seller duplicate checking accross campaign

const urlsToIntercept = ['fma_fac', 'listings/multiple/statuschange', 'duplicate_listings', 'statuschange/reassignment', 'sellers/check']
const requestInterceptChecking = async (config) => {
    await sleep(500)
    if (urlsToIntercept.some(u => config.url?.toLowerCase().includes(u.toLowerCase())) && store.getters.getRelatedCampaignBypass) {
        // console.log(config)
        config.headers["Allow-Checking-Bypass"] = 1
    }

    return config
    // console.log(config)
}

axios.interceptors.request.use((config) => {
    return requestInterceptChecking(config)
}, error => error)

HTTP.interceptors.request.use((config) => {
    return requestInterceptChecking(config)
}, error => error)
//#endregion


Vue.prototype.$http = HTTP;
Vue.prototype.isFeatureRestricted = (feature) => isFeatureRestricted(feature);
Vue.prototype.$featureAccess = featureAccessPrototype;

Vue.use(VueI18n);
const i18n = new VueI18n({ locale: localStorage.getItem('locale'), fallbackLocale: 'en' });

//Initialize Vue
const app = new Vue({
  data: () => ({ locale: localStorage.getItem('locale') ?? 'en' }),
  computed: {
    restrictedFeatures() {
        return useFeatureAccessStore().restrictedFeatures;
    },
  },
  pinia,
  i18n,
  store,
  router,
  render: h => h(App)
});

/**
 * @typedef { typeof app } VueApp
 */

store.$app = app;
window.vm = app;

app.$mount('#app');