//@ts-check

import axios from "axios";
import { socket } from ".";
import { getGuiSession } from '../utils/session';

/**
 * @type { 'development' | 'production' }
*/
// @ts-ignore
const appEnv = process.env.VUE_APP_ENV;

/**
 * Notify Campaign Lead that current assignment is exhausted.
 * 
 * @param {{campaignId: number; campaign: string; platform: string}} assignment
 */
export const send = async ({ campaignId, campaign, platform }) => {
  /**
   * @type {{ id: number, access_level: number, first_name: string, last_name: string; token: string }}
   */
  const user = getGuiSession();
  const isResearcher = user.access_level === 1;

  if (!isResearcher) {
    return;
  }

  const { first_name, last_name } = user;
  const researcher = `${first_name} ${last_name}`;

  socket().emit('exhausted-assignment-logs:send', { researcher, campaignId, campaign, platform, appEnv });
}

/**
 * @typedef { Object } ExhaustedLog
 * 
 * @property { string } researcher
 * @property { string } campaign
 * @property { number } campaignId
 * @property { string } platform
 */

/**
 * Listen whenever there is new combo exhausted by researcher.
 * 
 * @param { (exhaustedLog: ExhaustedLog) => void } callback
 */
export const listen = async (callback) => {
  socket()?.on('exhausted-assignment-logs:listen', async ( /** @type { ExhaustedLog } */ message) => {
    const { researcher, campaign, campaignId, platform } = message;

    // @ts-ignore
    if (message.appEnv !== appEnv) {
      return;
    }

    /**
     * @type {{ id: number; access_level: number; token: string }}
     */
    // @ts-ignore
    const { id, token, campaigns_and_campaignleads } = getGuiSession();

    // const { data } = await axios.get(`${process.env.VUE_APP_URL}/api/lead-campaigns/${id}?page_size=10000`, { headers: {Authorization: `Bearer ${token}`}});

    const data = campaigns_and_campaignleads?.filter(c => c.campaign_lead_id == id) ?? [];
    const campaignIds = data.map(( /** @type {{id: number}}*/ item ) => +item.id);

    const isInLeadCampaigns = campaignIds.includes(campaignId);

    if (!isInLeadCampaigns) {
      return;
    }

    callback({ researcher, campaignId, campaign, platform });
  });
}
