import axios from "axios";
import { socket } from ".";
import { getGuiSession } from '../utils/session';

/**
 * @type { 'development' | 'production' }
 */
const appEnv = process.env.VUE_APP_ENV;

console.log(socket())

export const signalNewDisabledCombo =  ({ campaignId }) => {
  socket().emit('signal-new-disabled-combo:send', { campaignId, appEnv });
}

export const listenNewDisabledCombo = (callback) => {
  socket()?.on('signal-new-disabled-combo:listen', async (message) => {
    const { campaignId } = message
    
    if (message.appEnv !== appEnv) {
      return;
    }

    /**
     * @type {{ id: number; access_level: number; token: string }}
     */
    const { id, token } = getGuiSession();

    // const { data } = await axios.get(`${process.env.VUE_APP_URL}/api/lead-campaigns/${id}?page_size=10000`, { headers: {Authorization: `Bearer ${token}`}});
    const data = campaigns_and_campaignleads?.filter(c => c.campaign_lead_id == id) ?? [];
    const campaignIds = data.map(( /** @type {{id: number}}*/ item ) => +item.id);

    const isInLeadCampaigns = campaignIds.includes(campaignId);

    if (!isInLeadCampaigns) {
      return;
    }

    callback({ campaignId });
  })
}
