// @ts-check

import { getGuiSession } from '../utils/session';
import { io } from 'socket.io-client';

/**
 * @type { import('socket.io-client').Socket }
 */
let websocket;

/**
 * @type { 'development' | 'production' }
 */
// @ts-ignore
const appEnv = process.env.VUE_APP_ENV;

export const initializeSocket = async () => {
  const { token } = getGuiSession() ?? {};

  if (token === undefined) {
    return;
  }

  const promise = await new Promise((resolve, reject) => {
    websocket = io(process.env.VUE_APP_WS_URL, { auth: { token }, transports: ['websocket'], reconnectionAttempts: 3, });

    websocket.on('connect', async () => {
      console.log('WebSocket Status: Connected');
      resolve('WebSocket Status: Connected');
    });
  
    websocket.on('disconnect', () => {
      console.log('WebSocket Status: Disconnected')
      reject('WebSocket Status: Disconnected');
    });

  });

  return promise;
}

export const socket = () => websocket;

/**
 * @typedef { object } Message
 * @property { string } type
 * @property {{ [key: string]: string }} data
 * @property { string } environment
 */

/**
 * Send message to socket server.
 * 
 * @param { Message } message 
 */
export const send = (message) => {
  if (message.type === undefined) {
    throw new Error('Argument must have property of type!');
  }

  if (message.data === undefined) {
    throw new Error('Argument must have property of data!');
  }

  message.environment = appEnv;

  socket().emit('send', message);
}

/**
 * Listen to incoming message from socket server.
 * 
 * @param { (message: Message) => void } callback 
 */
export const listen = (callback) => {
  socket()?.on('listen', (/** @type { Message } */ message) => {

    if (message.environment !== appEnv) {
      return;
    }

    callback(message);
  });
}

