let components = {}

require.context('@/components/layouts/', false, /.*\.vue$/)
    .keys()
    .forEach(component => {
        component = component.split('/')[1].split('.vue')[0]
        components['Ar'+component] = () => import('./components/layouts/'+component)
    })

const layouts = {
  components: {...components},
  getLayouts : function(components) {
    let views = {}
    components.forEach(layout => {
      views[layout] = this.components[layout]
    })
  
    return views
  }
}
/**
 * Usage:
 * 
 * All components 
 * components: {...vm.$layouts.components}
 *
 * Specific component(s)
 * components: {...vm.$layouts.getLayouts(['ArDialog'])}
 */

export default layouts
