export default class CustomCache {
    constructor() {
        this._originalValue = {};
        this._trackChanges = false;
        return new Proxy(this, {
            set: (object, key, value) => {
                if (!['_originalValue'].includes(key)) {
                    if (key in this._originalValue) {
                        if (object.compareValue(key)(this._originalValue[key], value)) {
                            delete this._originalValue[key];
                        }
                    }
                    else {
                        if (!object.compareValue(key)(object[key], value)) {
                            this._originalValue[key] = object[key];
                        }
                    }
                }

                object[key] = value;
                return true;
            }
        });
    }

    clearCache() {
        this._originalValue = {};
    }

    compareValue(key) {
        return function(oldValue, newValue) {
            return JSON.stringify(oldValue) === JSON.stringify(newValue);
        };
    }

    revertChanges() {
        for (let key of Object.keys(this._originalValue)) {
            this[key] = this._originalValue[key];
        }
    }

    get hasChanges() {
        return Object.keys(this._originalValue).length > 0;
    }
}